html,
body {
  min-width: 320px;
  width: 100%;
}

body {
  font-family: "Inter", sans-serif;
}

input {
  box-sizing: border-box;
}

a {
  color: #64b7dc;
  display: inline;
}

button {
  outline: 0;
  cursor: pointer;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #64b7dc;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
.richText ul {
  list-style: none;
  padding-left: 0;
}

.richText ul li::before {
  padding-left: 0px;
  content: "●";
  color: #72d1fb;
  font-weight: bold;
  display: inline-block;
  width: 2em;
  text-align: center;
}

.loader.secondary {
  border: 2px solid #ddd;
  border-top: 2px solid #64b7dc;
  width: 14px;
  height: 14px;
  margin-top: -2px;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

div.wmde-markdown {
  background-color: unset;
  color: rgb(68, 68, 68);
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}
